import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function BuinessAdvisory() {
  return (
    <>
        <Header></Header>
        <section className='tax-services'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - Services - Business Advisory
                      </p>
                      <p className='main-heading'>Business Advisory
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='tax-sercices-sec'>
            <div className='direct-tax-service'>
                <div className='container'>
                    {/* <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Accounting and Bookkeeping
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div className='service-content'>
                        <h2 className='semi-bold'>We take a holistic approach, crafting a comprehensive strategy tailored to your business.</h2>
                        <p>You need a trusted advisor. That’s where we add strong value.</p>
                        <p>Our clients span every stage of the business cycle—whether in startup, growth, or navigating transitions such as retirement. No matter where you are or the challenges you're facing, we customize our services to meet your unique needs, leveraging the expertise of our full-service team to provide support when you need it most. With our expert guidance, our clients rest easy, confident in the direction of their business.</p>
                            
                        <div className='tax-offers'>
                            <h2>Advisory Services</h2>
                            <ul>
                                <li>
                                Setting up Business Structure
                                    
                                </li>
                                <li>Management Advisory
                        
                                </li>
                                <li>
                                Business financing assistance
                                    
                                </li>
                                <li>
                                Cash flow projections
                                </li>
                                <li>
                                Corporate Structuring and restructuring
                                    
                                </li>
                            </ul>
                        </div>
                        <div className='tax-offers'>
                            <h2>Business Advisory Solutions</h2>
                            <p>As a business owner, are you contemplating any of the following decisions?</p>
                            <ul>
                                <li>
                                Should I open a new facility?
                                    
                                </li>
                                <li>What is the optimal pricing for my new product line?
                        
                                </li>
                                <li>
                                How can I effectively manage cash flow when embarking on a resource-intensive project?
                                    
                                </li>
                                <li>
                                How much can I afford to pay my employees, and when should I expand my workforce to meet growing demand?
                                </li>
                                <li>
                                When will my new facility become profitable, and what return on investment can I expect?
Rather than relying solely on intuition, business owners can mitigate risk by partnering with a trusted advisor at Mokx. With our financial expertise, we can help evaluate the risks and long-term financial benefits of your decisions, ensuring you make informed choices with confidence.

                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> 
            </div>
            
            <div className='faqs-sec'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Frequently asked questions (faq)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='faqs-content'>
                        <div className='row'>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            What is involved in setting up a business structure?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample" >
                                            <div class="accordion-body">Setting up a business structure involves determining the most suitable legal entity for your business, such as a sole proprietorship, partnership, corporation, or limited liability company (LLC). We assess your business goals, tax implications, liability concerns, and operational needs to recommend the optimal structure for your situation.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="true" aria-controls="flush-collapsetwo">
                                            Why is choosing the right business structure important?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsetwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Choosing the right business structure is crucial because it affects your liability, tax obligations, and operational flexibility. The right structure can help minimize your tax burden, protect personal assets, and facilitate easier access to financing and investment opportunities.</div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="true" aria-controls="flush-collapsethree">
                                            What does management advisory entail?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsethree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Management advisory services provide strategic guidance to improve your business's overall performance. This includes evaluating operational efficiency, financial management, and organizational structure, as well as identifying opportunities for growth and risk mitigation.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="true" aria-controls="flush-collapsefour">
                                            Can you help with strategic planning for my business?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Yes, we assist with strategic planning by facilitating discussions on your business's vision, goals, and objectives. We help you develop a clear action plan, set measurable targets, and establish key performance indicators (KPIs) to track your progress.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="true" aria-controls="flush-collapsefive">
                                            How do you assess the financial health of my business?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">We assess your business's financial health by analyzing key financial statements, cash flow trends, and ratios (such as profitability, liquidity, and solvency ratios). This comprehensive analysis helps identify strengths and weaknesses and informs future decision-making.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="true" aria-controls="flush-collapsesix">
                                            How can I get started with your business advisory services?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">To get started, simply contact us to schedule a consultation. We’ll discuss your business goals and challenges and outline how our services can help you achieve success. We tailor our approach to fit your unique circumstances and ensure you receive the support you need.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer></Footer>
    </>
  )
}

export default BuinessAdvisory;