import './App.css';
// import logo from './Mokx-website-logo-svg.svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import Header from './components/Header';
import Footer from './components/Footer';
import { HashLink } from 'react-router-hash-link';


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={'nextarrow'}
      style={{ ...style, }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={'prevarrow'}
      style={{ ...style, }}
      onClick={onClick}
    />
  );
}

function App() {

  const reviewSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
     nextArrow: <NextArrow />,
     prevArrow: <PrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 688,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <div className="App">
      <Header></Header>

      <div className='home-banner row'>
        <div className='text-sec col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5'>
          <p className='heading cinzel-decorative-bold'>
            We Provide Best Accounting & Tax Service For You
          </p>
          <p className='para-text'>
          Tax services that are customized to enhance your financial strategy and minimize tax liabilities. Trust us for tax preparation, planning, and filing needs to thrive in today's competitive market.
          </p>
          <div className='g-review'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326667 333333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z" fill="#4285f4"/><path d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z" fill="#34a853"/><path d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z" fill="#fbbc04"/><path d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z" fill="#ea4335"/>
            </svg>
            <div className='google-review-text'>
              <p>Google rating score: 5 of 5 </p>
              <p className='stars'>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                  </svg>
                </span>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                  </svg>
                </span>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                  </svg>
                </span>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                  </svg>
                </span>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                  </svg>
                </span>
              </p>
              <p>based on 1.9K+ reviews |</p>
              <p><a href="">View All Reviews</a></p>
            </div>
          </div>
        </div>
        <div className='img-sec col-12 col-sm-12 col-md-7 col-xl-7 col-xxl-7'>
          <img className='img-fluid w-100' src={require('./images/mokx-banner.png')} alt='Mokx Professional Services Ltd.'/>
        </div>
      </div>

      <section className='static-numbers-sec'>
        <div className='container container-sm container-md container-xl container-xxl'>
          <div className='heding-sec'>
              <div className='sec-heading'>
                  <p className='small-heading'>
                    VALUE YOU NEED
                  </p>
                  <p className='main-heading'>Try our all-in-one solution for accounting tax, and bookkeeping. You will never look back!
                  </p>
              </div>
          </div>
          <div className='static-details'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>1</div>
                  <div className='static-content-inner'>
                    <p className='static-content-heading'>Spot in Google review in Accounting firm across Canada</p>
                    <p>
                    We don’t just say we provide great service.
                    we have proved it!
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>40</div>
                  <div className='static-content-inner' style={{margin: '0px 0px 0px 40px'}}>
                    <p className='static-content-heading' style={{margin: '0px 0px 45px 0px'}}>Enthusiastic team members</p>
                    <p>
                    Our CPA’s and CA’s are experienced and responsive.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>10</div>
                  <div className='static-content-inner'>
                    <p className='static-content-heading'>years of services to privately held business and individuals</p>
                    <p>
                    Our approach is thoroughly modern and digital! 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='services-sec'>
        <div className='container container-sm container-md container-xl container-xxl'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='heding-sec'>
                <div className='sec-heading'>
                    <p className='small-heading'>
                      MOKX SERVICES
                    </p>
                    <p className='main-heading'>Our BEST services that will boost your finanace
                    </p>
                </div>
              </div>
              <div className='key-btn'>
                <p>Top 5 Services We Serve</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='services-cards'>
                <div className='card'>
                  <div className='circle-div'>
                  <img className='img-fluid' src={require('./images/tax-img.png')} alt='Mokx Tax Service'/>
                  </div>
                  <div className='card-title'>
                    <p>Tax</p>
                  </div>
                  <div className='card-description'>
                    <p>At Mokx Professionals, we are dedicated to provide you with comprehensive guidance on the implications of evolving tax regulations and rulings on your business...</p>
                  </div>
                  <div className='read-more-btn'>
                      <HashLink smooth to='../tax-services#top' href=''>Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></HashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='services-cards'>
                <div className='card'>
                  <div className='circle-div'>
                  <img className='img-fluid' src={require('./images/tax-img.png')} alt='Mokx Tax Service'/>
                  </div>
                  <div className='card-title'>
                    <p>Payroll</p>
                  </div>
                  <div className='card-description'>
                    <p>Your payroll process transcends a mere obligatory business function; it serves as a pivotal instrument for managing significant expenditures and obligations, thereby...</p>
                  </div>
                  <div className='read-more-btn'>
                      <HashLink smooth to='../payroll-services#top'>Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></HashLink>
                  </div>
                </div>
              </div>
            </div>  
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='services-cards'>
                <div className='card'>
                  <div className='circle-div'>
                  <img className='img-fluid' src={require('./images/tax-img.png')} alt='Mokx Tax Service'/>
                  </div>
                  <div className='card-title'>
                    <p>Accounting & Bookkeeping</p>
                  </div>
                  <div className='card-description'>
                    <p>Accurate financial reporting is essential to the success of any business. Contact us to learn more about our accounting services...</p>
                  </div>
                  <div className='read-more-btn'>
                      <HashLink  smooth to='../accountingandbookkeeping-services#top'>Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></HashLink>
                  </div>
                </div>
              </div>
            </div>  
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='services-cards'>
                <div className='card'>
                  <div className='circle-div'>
                  <img className='img-fluid' src={require('./images/tax-img.png')} alt='Mokx Tax Service'/>
                  </div>
                  <div className='card-title'>
                    <p>Software Implementation Support Service</p>
                  </div>
                  <div className='card-description'>
                    <p>Choosing the appropriate accounting software is paramount to laying a robust foundation for your business. The right...</p>
                  </div>
                  <div className='read-more-btn'>
                      <HashLink smooth to='../software-implementation-support-services#top'>Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></HashLink>
                  </div>
                </div>
              </div>
            </div>  
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='services-cards'>
                <div className='card'>
                  <div className='circle-div'>
                  <img className='img-fluid' src={require('./images/tax-img.png')} alt='Mokx Tax Service'/>
                  </div>
                  <div className='card-title'>
                    <p>Business <br></br>Advisory</p>
                  </div>
                  <div className='card-description'>
                    <p>We take a holistic approach, crafting a comprehensive strategy tailored to your business...</p>
                  </div>
                  <div className='read-more-btn'>
                      <HashLink smooth to='/buiness-advisory-services#top'>Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></HashLink>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </section>

      <section className='why-choose-moks-sec'>
        <div className='container container-sm container-md container-xl container-xxl'>
          <div className='heding-sec'>
            <div className='sec-heading'>
                <p className='small-heading'>
                Why you should choose Mokx?
                </p>
                <p className='main-heading'>We deliver exceptional accounting and tax services.
                </p>
                
            </div>
            
          </div>
          <p className='my-3'>Construct your financial future with support from Mokx</p>
          <div className='row mt-5'>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Best Acounting Service</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Expert Accountants</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Timely Support</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Comprehensive Financial Services</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Reliable Tax Assistance</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Personalized Financial Planning</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Accurate Bookkeeping</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Proactive Financial Advice</p>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4 mt-4'>
              <div className='key-card'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.93 17.042C39.976 16.694 40 16.346 40 16C40 11.242 35.714 7.424 30.958 8.07C29.572 5.604 26.932 4 24 4C21.068 4 18.428 5.604 17.042 8.07C12.276 7.424 8 11.242 8 16C8 16.346 8.024 16.694 8.07 17.042C5.604 18.43 4 21.07 4 24C4 26.93 5.604 29.57 8.07 30.958C8.02386 31.3034 8.00047 31.6515 8 32C8 36.758 12.276 40.566 17.042 39.93C18.428 42.396 21.068 44 24 44C26.932 44 29.572 42.396 30.958 39.93C35.714 40.566 40 36.758 40 32C40 31.654 39.976 31.306 39.93 30.958C42.396 29.57 44 26.93 44 24C44 21.07 42.396 18.43 39.93 17.042ZM21.91 32.832L14.576 25.404L17.424 22.596L21.938 27.168L30.592 18.58L33.408 21.42L21.91 32.832Z" fill="#FFC107"/>
                </svg> <p>Dedicated Client Support</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className='work-process-sec'>
        <div className='container container-sm container-md container-xl container-xxl'>
          <div className='row'>
              <div className='col-12 col-sm-12 col-md-7 col-xl-7 col-xxl-7 '>
                <div className='prcess-cards w-75'>
                    <div className='steps-cards'>
                      <div className='steps-number'>1</div>
                      <div className='steps-content-inner'>
                        <p className='steps-content-heading'>Free consultation call</p>
                      </div>
                    </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3">
                      <div className='setps-img'>
                        {/* <img className='img-fluid' src={require('./images/step2-image.png')} alt='Mokx Professional Services Ltd.'/> */}
                      </div>
                      
                  </div>
                  <div className='col-12 col-sm-12 col-md-9 col-xl-9 col-xxl-9'>
                    <div className='prcess-cards'>
                      <div className='steps-cards'>
                        <div className='steps-number'>2</div>
                        <div className='steps-content-inner'>
                          <p className='steps-content-heading'>Engagement agreement</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='prcess-cards w-75'>
                  <div className='steps-cards'>
                    <div className='steps-number'>3</div>
                    <div className='steps-content-inner'>
                      <p className='steps-content-heading'>Data collection and analysis</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                      <div className='setps-img'>
                        {/* <img className='img-fluid' src={require('./images/step2-image.png')} alt='Mokx Professional Services Ltd.'/> */}
                      </div>
                      
                  </div>
                  <div className='col-12 col-sm-12 col-md-10 col-xl-10 col-xxl-10'>
                    <div className='prcess-cards'>
                      <div className='steps-cards'>
                        <div className='steps-number'>4</div>
                        <div className='steps-content-inner'>
                          <p className='steps-content-heading'>Tax planning and strategy development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='prcess-cards w-75'>
                  <div className='steps-cards'>
                    <div className='steps-number'>5</div>
                    <div className='steps-content-inner'>
                      <p className='steps-content-heading'>Bookkeeping and preparation of financial statements</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-12 col-sm-12 col-md-2 col-xl-2 col-xxl-2">
                      <div className='setps-img'>
                        {/* <img className='img-fluid' src={require('./images/step2-image.png')} alt='Mokx Professional Services Ltd.'/> */}
                      </div>
                      
                  </div>
                  <div className='col-12 col-sm-12 col-md-10 col-xl-10 col-xxl-10'>
                    <div className='prcess-cards'>
                      <div className='steps-cards'>
                        <div className='steps-number'>6</div>
                        <div className='steps-content-inner'>
                          <p className='steps-content-heading'>Final review & Discussion with the clients</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5 '>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Mokx’s work process
                      </p>
                      <p className='main-heading w-100'>The work process of corporate clients
                      </p>
                  </div>
                </div>
                <p className='my-3'>Our structured process ensures tailored financial solutions, continuous support, and strategic growth for our corporate clients.</p>
                <img className='img-fluid' src={require('./images/writing-work-process.png')} alt='Mokx Professional Services Ltd.'/>
              </div>
            </div>
        </div>
      </section>

      <section className='client-reviews'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5 '>
              <img className='img-fluid' src={require('./images/client.png')} alt='Mokx Professional Services Ltd.'/>
            </div>
            <div className='col-12 col-sm-12 col-md-7 col-xl-7 col-xxl-7 '>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Mokx's Clients Says
                      </p>
                      <p className='main-heading w-100'>Our client’s reviews inspired us the most to improve our services
                      </p>
                  </div>
                </div>
                <div className='review-slider'>
                  <Slider {...reviewSlider}>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/dq-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“Mokx provided exceptional service that exceeded my expectations as a first-time business owner. Their expert guidance was instrumental in helping me navigate the complexities of my inaugural year, offering invaluable insights that directly contributed to my business's growth. What truly distinguishes Mokx is their meticulous attention to detail and unwavering dedication to client success. I consistently feel confident that I’m receiving the best possible support, and their prompt, thorough responses to my inquiries have only enhanced the experience. Mokx has proven to be a trusted partner, ensuring that every aspect of my financial management is handled with the highest level of professionalism.</p>
                      </div>
                      <div className='client-name'>
                        <p>Vishwas Patel (Franchise Owner)</p>
                      </div>
                    </div>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/netcarat-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“I had an outstanding experience with the Mokx team, and I can't recommend them highly enough. From setting up our accounting system to handling corporate tax, HST, and payroll, their expertise was evident at every step. The team was professional, responsive, and meticulous, ensuring everything was done correctly and efficiently. Their guidance in business advisory services was invaluable, helping us make informed decisions that have positively impacted our growth. They tailored their solutions to meet the specific needs of our company, demonstrating deep understanding of our industry and goals. The seamless integration of all these services made managing our finances much easier, allowing us to focus on other areas of our business. Thanks to Mokx, we have a solid foundation for future success. Their dedication to excellence truly sets them apart.</p>
                      </div>
                      <div className='client-name'>
                        <p>Paresh Dobariya (President)</p>
                      </div>
                    </div>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/non-stop-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“I am absolutely delighted with the service provided by Mokx. Their team was exceptionally helpful, explaining everything in a manner that was easy to grasp. They assisted me with both my business taxes and personal finances, instilling confidence in me at every stage of the process. The meticulous attention to detail and the quality of service they offer are truly remarkable. </p>
                      </div>
                      <div className='client-name'>
                        <p>Kamaljeet Singh (CEO)</p>
                      </div>
                    </div>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/mrsub-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“They possess an impressive depth of knowledge, demonstrating professionalism and a keen attention to detail. I valued their proactive approach to tax planning, which effectively saved me both time and money. Communication was effortless; they consistently kept me informed and addressed all my inquiries without delay.</p>
                      </div>
                      <div className='client-name'>
                        <p>Parth Patel (Franchise Owner)</p>
                      </div>
                    </div>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/pizzapizza-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“Partnering with Mokx has been transformative for my business. Their proficiency in accounting and tax planning has offered me insights I never realized I needed. The team is both professional and approachable, consistently available to address my inquiries. They guided me through a complex tax situation with remarkable ease, ensuring I felt supported every step of the way. </p>
                      </div>
                      <div className='client-name'>
                        <p>Parth Soni (Franchise Owner)</p>
                      </div>
                    </div>
                    <div className='client-reviews-card'>
                      <div className='client-logo'>
                        <img className='img-fluid' src={require('./images/stuff-logo.png')} alt='Mokx Professional Services Ltd.'/>
                      </div>
                      <div className='review-stars'>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                          </svg>
                        </span>
                      </div>
                      <div className='review-content'>
                        <p>“My experience with Mokx Professional Services Ltd. was nothing short of exceptional. The team exemplifies both professionalism and approachability, creating an atmosphere of trust and collaboration. They meticulously explained the intricacies of the tax process, making complex concepts clear and accessible. Their personalized approach made me feel genuinely valued as a client, and their proactive recommendations for optimizing my accounting and tax strategies were both insightful and highly beneficial. I truly appreciate their commitment to delivering tailored, thoughtful solutions. </p>
                      </div>
                      <div className='client-name'>
                        <p>Ronak Patel (Franchise Owner)</p>
                      </div>
                    </div>
                  </Slider>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section className='working-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6 '>
              <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Mokx's working area
                      </p>
                      <p className='main-heading w-100'>Industries that we served Across Canada
                      </p>
                  </div>
                 
                </div>
                <p className='my-3'>Tax services that are customized to enhance your financial strategy and minimize tax liabilities. Trust us for tax preparation, planning, and filing needs to thrive in todays competitive market.</p>
                <div className='industry-list'>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Construction</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Retail</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Transportation and Logistics</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Cleaning and Maintenance</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Food and Hospitality</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Consulting and Professional Services</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Ecommerce and Technology</p>
                  </div>
                  
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Medical and Health Services</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Electrical and Industrial Goods</p>
                  </div>
                  <div className='industry-names'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                    <p>Accounting and Finance</p>
                  </div>
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6 '>
            <img className='img-fluid ' src={require('./images/indus-we-serve.png')} alt='Mokx Professional Services Ltd.'/>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      
    </div>
    
  );
}

export default App;
