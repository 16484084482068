import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function Payroll() {
  return (
    <>
        <Header></Header>
        <section className='tax-services'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - Services - Payroll Services
                      </p>
                      <p className='main-heading'>Payroll service
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='tax-sercices-sec'>
            <div className='direct-tax-service'>
                <div className='container'>
                    {/* <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Payroll
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div className='service-content'>
                        <h2 className='semi-bold'>Reliable and comprehensively managed payroll services designed to facilitate the advancement of your business.</h2>
                        <p>Your payroll process transcends a mere obligatory business function; it serves as a pivotal instrument for managing significant expenditures and obligations, thereby fostering employee trust through the provision of timely and precise remuneration. Ensure compliance with local jurisdictions when calculating and disbursing payments.</p>
                        <p>Inadequate payroll management can result in high employee turnover, laborious manual computations for statutory holidays and vacation pay, failure to keep abreast of legislative updates, and inaccuracies in year-end government agency filings. Such payroll errors may incur substantial penalties and interest upon reassessment. Outsourcing payroll mitigates these risks while conserving both time and financial resources.</p>
                        <p>
                        Our professional payroll managers work closely with you to provide support and guidance throughout the process being careful to ensure minimal impact to your employees.
                        </p>
                        <p>
                        In addition, we offer specialized services in Workplace Compliance Board management and Employer Health Tax to ensure compliance with all remittance requirements and filing requirements.
                        </p>
                            
                        <div className='tax-offers'>
                            <h2>Payroll Services We Offer</h2>
                            <p>We provide advice and services for the following:</p>
                            <ul>
                                <li>
                                Payroll Processing
                                    <ol type="I">
                                        <li>Calculating employee wages and salaries</li>
                                        <li>Deductions for taxes, benefits, and other withholdings</li>
                                        <li>Direct deposit of employee pay</li>
                                    </ol>
                                </li>
                                <li>Payroll Remittances
                                    <ol type="I">
                                        <li>Submission of payroll taxes to government agencies</li>
                                        <li>Regular remittance of CPP/QPP, EI, and federal/provincial taxes</li>
                                        <li>Reporting and payment of employer contributions</li>
                                    </ol>
                                </li>
                                <li>
                                Issuing Record of Employment (ROE)
                                    <ol type="I">
                                        <li>Preparing and filing ROE for employees who leave the company</li>
                                        <li>Ensuring compliance with Employment Insurance (EI) requirements</li>
                                    </ol>
                                </li>
                                <li>
                                Tax diagnostic reviews.
                                </li>
                                <li>
                                Year-End Payroll Processing
                                    <ol type="I">
                                        <li>Preparation and filing of T4/T4A slips and summaries for employees</li>
                                        <li>Year-end payroll reports for tax filing</li>
                                        <li>Balancing and reconciling year-end payroll figures</li>
                                    </ol>
                                </li>
                                <li>
                                Worker's Compensation Board (WCB) Compliance
                                    <ol type="I">
                                        <li>Calculating and remitting WCB premiums</li>
                                        <li>Filing of WCB returns and reports</li>
                                        <li>Managing workplace injury claims</li>
                                    </ol>
                                </li>
                                <li>
                                Employer Health Tax (EHT) Compliance
                                        <ol type="I">
                                        <li>Calculating EHT for applicable jurisdictions</li>
                                        <li>Filing and remitting EHT payments</li>
                                        <li>Ensuring compliance with provincial regulations for health taxes</li>
                                    </ol>
                                </li>
                            </ul>
                        </div>

                        <p>These services ensure comprehensive management of payroll obligations, helping businesses stay compliant with both federal and provincial requirements.</p>
                    </div>
                </div> 
            </div>
            
            <div className='faqs-sec'>
                <div className='container'>
                    <div className='sec-title'>
                        <div className='heding-sec'>
                            <div className='sec-heading'>
                                <p className='main-heading'>Frequently asked questions (faq)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='faqs-content'>
                        <div className='row'>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            What does payroll processing involve?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample" >
                                            <div class="accordion-body">Payroll processing includes calculating employee wages, deductions (such as income tax, CPP, and EI), and net pay. It also involves ensuring compliance with provincial and federal regulations, generating pay stubs, and maintaining accurate payroll records.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="true" aria-controls="flush-collapsetwo">
                                            What are payroll remittances, and how often do they need to be submitted?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsetwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Payroll remittances are the employer's payments to the Canada Revenue Agency (CRA) for employee income tax, Canada Pension Plan (CPP) contributions, and Employment Insurance (EI) premiums. Remittances are generally due monthly, but some employers may qualify for quarterly or annual remittance schedules, depending on their payroll size.</div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="true" aria-controls="flush-collapsethree">
                                            What is a Record of Employment (ROE), and when should it be issued?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsethree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">A Record of Employment (ROE) is an official document issued by employers when an employee experiences an interruption in earnings, such as termination, resignation, or a period of leave. ROEs must be issued within five calendar days after the end of an employee’s employment or interruption in earnings.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="true" aria-controls="flush-collapsefour">
                                            What is involved in year-end payroll processing?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Year-end payroll processing includes reconciling payroll records, preparing T4 slips for employees, submitting the T4 Summary to the CRA, and ensuring that all deductions and contributions have been correctly calculated throughout the year. This process also includes reviewing and correcting any discrepancies to ensure compliance.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="true" aria-controls="flush-collapsefive">
                                            What is Workers' Compensation Board (WCB) coverage, and do all employers need it?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">WCB coverage is a form of insurance that provides compensation to workers who suffer workplace injuries or illnesses. In most provinces, employers are required by law to register with the Workers' Compensation Board if they have employees, and premiums are paid based on the employer’s industry and payroll size.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="true" aria-controls="flush-collapsesix">
                                            What is the Employer Health Tax (EHT), and who needs to pay it?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">The Employer Health Tax (EHT) is a payroll tax levied on employers in certain provinces, such as Ontario and British Columbia, to help fund public health care. The tax applies to businesses whose total annual payroll exceeds the provincial threshold. EHT rates and thresholds vary by province.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="true" aria-controls="flush-collapseseven">
                                            What are the penalties for late payroll remittances or filings?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">The CRA imposes penalties and interest on employers who fail to remit payroll deductions or file payroll documents (such as T4 slips) by the deadline. Penalties can range from 3% to 10% of the amount owing, depending on how late the remittance or filing is.</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseight" aria-expanded="true" aria-controls="flush-collapseight">
                                            How does payroll compliance vary between provinces?
                                            </button>
                                            </h2>
                                            <div id="flush-collapseight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">The Payroll compliance, such as minimum wage, vacation pay, and statutory holiday requirements, can vary significantly between provinces. Employers must adhere to both federal and provincial regulations for deductions, remittances, and employee entitlements.
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsnine" aria-expanded="true" aria-controls="flush-collapsnine">
                                            Can I outsource payroll processing?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsnine" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">Yes, many businesses outsource payroll processing to specialized service providers to ensure accuracy, compliance with tax regulations, and time efficiency. Outsourcing can also reduce the risk of errors and the administrative burden on your in-house team.
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsten" aria-expanded="true" aria-controls="flush-collapsten">
                                            What support do you provide for payroll audits?
                                            </button>
                                            </h2>
                                            <div id="flush-collapsten" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">We provide comprehensive support during payroll audits, including documentation review, ensuring compliance with federal and provincial regulations, and representing your business in dealings with the CRA or provincial agencies.
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-xl-6 col-xxl-6 mt-3'>
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapselevn" aria-expanded="true" aria-controls="flush-collapsten">
                                            What do I need to provide to set up payroll for my business?
                                            </button>
                                            </h2>
                                            <div id="flush-collapselevn" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">To set up payroll, you’ll need your business number (BN), employee details (including SINs and tax forms), salary information, and any applicable deductions or contributions (such as for benefits or pension plans). We can help ensure you meet all regulatory requirements.
                                            </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
        <Footer></Footer>
    </>
  )
}

export default Payroll;